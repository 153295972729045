import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import ProblemType from "../components/problemtype/ProblemType";
import ProblemCategory from "../components/problemcategory/ProblemCategory";
import TapeDamaged from "../components/tapedamaged/TapeDamaged";
import ProblemAction from "../components/problemAction/ProblemAction";
import SelectStation from "../components/selectstation/SelectStation";
import ProductItems from "../components/productitems/ProductItems";
import SuccessScreen from "../components/successscreen/SuccessScreen";
import ErrorScreen from "../components/errorscreen/ErrorScreen";
import SelectDriver from "../components/selectdriver/SelectDriver";
import QRPickupExceptions from "../components/qrpickupexception/QRPickupExceptions";
import Column from "@amzn/meridian/column";
import TopNavigation from "../components/common/TopNavigation";
import {injectIntl} from "react-intl";
import StageProposedSolution from "../components/problemAction/stageItemAction/stageProposedSolution";
import StageSolutionAction from "../components/problemAction/stageItemAction/StageSolutionAction";
import PrintSuccessScreen from "../print/PrintSuccessScreen";
import ReprintLabels from "../print/ReprintLabels";
import ConnectPrinter from "../print/ConnectPrinter";
import DolphinModuleRedirector from "../components/common/DolphinModuleRedirector";
import HazmatScanView from "../components/hazmat/HazmatScanView";
import PrintActiveLabel from "../print/PrintActiveLabel";
import RTOSuccessScreen from "../components/successscreen/RTOSuccessScreen";
import RTOPrintSuccessScreen from "../components/successscreen/RTOPrintSuccessScreen";
import RTODestroySuccessScreen from "../components/successscreen/RTODestroySuccessScreen";
import NetworkMissortSuccessScreen from "../components/successscreen/NetworkMissortSuccessScreen";
import AppContextProvider from "../components/backPressedView/AppContext";
import SalColorAudit from "../components/salColorAudit/SalColorAudit";
import SelectPackageSize from "../components/selectpackagesize/SelectPackageSize";
import SelectSector from "../components/selectsector/SelectSector";

export const PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS = {
  PROBLEM_TYPE: "/ps/problemType",
  PROBLEM_ACTION: "/ps/problemAction",
  SAL_COLOR_AUDIT: "/ps/salColorAudit",
  PROBLEM_CATEGORY: "/ps/problemCategory/:problemType",
  COMPONENT_TAPE_DAMAGED: "/ps/component/TAPE_DAMAGED",
  COMPONENT_SELECT_STATION: "/ps/selectStation",
  COMPONENT_SELECT_DRIVER: "/ps/selectDriver",
  HELP_CENTER: "/ps/helpcenter",
  PRODUCT_ITEMS: "/ps/productItems",
  UPDATE_EXCEPTION: "/ps/updateException",
  SUCCESS_SCREEN: "/ps/successScreen",
  ERROR_SCREEN: "/ps/errorScreen",
  QR_PICKUP_EXCEPTION_SCREEN: "/ps/qrPickupExceptionScreen",
  STAGE_ITEM: "/ps/stageItem",
  STAGE_SOLUTION_ACTION: "/ps/stageSolutionAction",
  PRINT_SUCCESS_SCREEN: "/ps/printSuccessScreen",
  REPRINT_LABELS : "/ps/reprintLabels",
  CONNECT_PRINTER: "/ps/connectPrinter",
  DOLPHIN_MODULE_REDIRECTOR: "/ps/dolphinModuleRedirector",
  HAZMAT_SCAN: "/ps/hazmatScan",
  PRINT_ACTIVE_LABEL: "/ps/printActiveLabel",
  RTO_SUCCESS_SCREEN: "/ps/rtoSuccessScreen",
  RTO_PRINT_SUCCESS_SCREEN: "/ps/rtoPrintSuccessScreen",
  RTO_DESTROY_SUCCESS_SCREEN: "/ps/rtoDestroySuccessScreen",
  NETWORK_MISSORT_SUCCESS_SCREEN: "/ps/networkMissortSuccessScreen",
  NETWORK_MISSORT_PRINT_SUCCESS_SCREEN: "/ps/networkMissortPrintSuccessScreen",
  SELECT_PACKAGE_SIZE: "/ps/selectPackageSize",
  SELECT_SECTOR: "/ps/selectSector"
};

const ProblemSolveExecutionRouter = () => {
  return (
      <div>
        <AppContextProvider>
          <TopNavigation title="operation_problem_solve"/>
          <div className="content">
            <Column height="100vh">
              <Switch>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_TYPE} component={withRouter(injectIntl(ProblemType))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_CATEGORY} component={withRouter(injectIntl(ProblemCategory))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION} component={withRouter(injectIntl(ProblemAction))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_TAPE_DAMAGED} component={withRouter(injectIntl(TapeDamaged))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_SELECT_STATION} component={withRouter(SelectStation)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.COMPONENT_SELECT_DRIVER} component={withRouter(SelectDriver)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PRODUCT_ITEMS} component={withRouter(ProductItems)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN} component={withRouter(SuccessScreen)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.ERROR_SCREEN} component={withRouter(ErrorScreen)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.QR_PICKUP_EXCEPTION_SCREEN} component={withRouter(injectIntl(QRPickupExceptions))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.STAGE_ITEM} component={withRouter(injectIntl(StageProposedSolution))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.STAGE_SOLUTION_ACTION} component={withRouter(injectIntl(StageSolutionAction))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PRINT_SUCCESS_SCREEN} component={withRouter(injectIntl(PrintSuccessScreen))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.REPRINT_LABELS} component={withRouter(injectIntl(ReprintLabels))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.CONNECT_PRINTER} component={withRouter(injectIntl(ConnectPrinter))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.DOLPHIN_MODULE_REDIRECTOR} component={withRouter(injectIntl(DolphinModuleRedirector))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.HAZMAT_SCAN} component={withRouter(injectIntl(HazmatScanView))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PRINT_ACTIVE_LABEL} component={withRouter(injectIntl(PrintActiveLabel))}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.RTO_SUCCESS_SCREEN} component={withRouter(RTOSuccessScreen)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.RTO_PRINT_SUCCESS_SCREEN} component={withRouter(RTOPrintSuccessScreen)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.RTO_DESTROY_SUCCESS_SCREEN} component={withRouter(RTODestroySuccessScreen)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.NETWORK_MISSORT_SUCCESS_SCREEN} component={withRouter(NetworkMissortSuccessScreen)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SAL_COLOR_AUDIT} component={withRouter(SalColorAudit)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SELECT_PACKAGE_SIZE} component={withRouter(SelectPackageSize)}/>
                <Route path={PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SELECT_SECTOR} component={withRouter(SelectSector)}/>
              </Switch>
            </Column>
          </div>
        </AppContextProvider>
      </div>
  );
};

export default ProblemSolveExecutionRouter;
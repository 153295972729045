import {
    callActionApi,
    getMessageAndAlertTypeForUpdateExceptionShipment,
    handleInlineError,
    processApiExecutionResponse
} from "../helper/ActionComponentsHelper";
import {
    NOTIFICATION_TYPE,
    PROBLEM_ACTION_API,
} from "../constants/Constants";
import {saveExceptionResolutionResult} from "../dataStore/PSExecutionDataStore";
import {useHistory} from "react-router";
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../router/ProblemSolveExecutionRouter";
import {GetStationListAPI} from "../network/apis/npsw/GetStationListAPI";

export const GetStationListInitialState = {
    loading: false,
    stationList: [],
    notificationType: "",
    notificationMessageId: ""
};

export const GET_STATION_LIST_ACTION_TYPE = {
    FETCH_STATION_LIST: "FETCH_STATION_LIST",
    SET_STATION_LIST: "SET_STATION_LIST",
    ERROR: "ERROR",
    HANDLE_INLINE_ERROR: "HANDLE_INLINE_ERROR",
    SET_NEW_STATION: "SET_NEW_STATION",
    SET_NEW_STATION_RESPONSE: "SET_NEW_STATION_RESPONSE",
    CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
};

export const GetStationListReducer = (state, action) => {
    switch (action.type) {
        case GET_STATION_LIST_ACTION_TYPE.FETCH_STATION_LIST:
            GetStationListAPI(action.data, action.dispatcher);
            return {...state, loading: true};
        case GET_STATION_LIST_ACTION_TYPE.SET_STATION_LIST:
            return {...state, stationList: action.data, loading: false};
        case GET_STATION_LIST_ACTION_TYPE.HANDLE_INLINE_ERROR:
            const alertParameters = getMessageAndAlertTypeForUpdateExceptionShipment(action.data)
            return {...state, loading: false, notificationType: alertParameters.alertType,
                notificationMessageId: alertParameters.alertMessageId}
        case GET_STATION_LIST_ACTION_TYPE.ERROR:
            return {
                ...state, loading: false, notificationType: NOTIFICATION_TYPE.ALERT,
                notificationMessageId: "internal_error"
            };
        case GET_STATION_LIST_ACTION_TYPE.SET_NEW_STATION:
            callActionApi({
                    api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
                    properties: {
                        targetAction: "WRONG_PLANNED_STATION_TRANSFER"
                    },
                    additionalProperties: {"TARGET_STATION": action.data}
                }, action.dispatch,
                GET_STATION_LIST_ACTION_TYPE.SET_NEW_STATION_RESPONSE,
                GET_STATION_LIST_ACTION_TYPE.ERROR);
            return {...state, loading: true};
        case GET_STATION_LIST_ACTION_TYPE.SET_NEW_STATION_RESPONSE: {
            const {exceptionResolutionResult} = processApiExecutionResponse(action.data);
            if(exceptionResolutionResult.isError) {
                handleInlineError(action.data, GET_STATION_LIST_ACTION_TYPE.HANDLE_INLINE_ERROR, action.dispatcher)
                return {...state};
            }
            saveExceptionResolutionResult(exceptionResolutionResult);
            //eslint-disable-next-line react-hooks/rules-of-hooks
            useHistory().push({
                pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.SUCCESS_SCREEN,
                state: {
                    properties: {
                        isPrintLabelSupported: true
                    },
                    metaData: action.metaData
                }
            });
            return {...state, loading: false};
        }
        case GET_STATION_LIST_ACTION_TYPE.CLOSE_NOTIFICATION:
            return {...state, notificationType: null};
        default:
            return state;
    }
};
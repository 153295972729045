export const HTTP_METHOD = {
    GET:"GET",
    POST:"POST"
};

export const MIDDLE_MILE = "MIDDLE_MILE";
export const LAST_MILE = "LAST_MILE";

export const FEATURE_KEY = {
    EXCEPTION_SIZE_THRESHOLD: "EXCEPTION_SIZE_THRESHOLD",
    EXCEPTION_SIZE_THRESHOLD_DEFAULT_VALUE: 2000
};

export const TARGET_PROBLEM_STATUS = {
    OPEN: "OPEN",
    RESOLVED: "RESOLVED"
}

export const SAL_COLOR_MAP = {
    "#FEE966": "Yellow",
    "#C1E766": "Green",
    "#9DDAF1": "Blue",
    "#FDC3D1": "Pink"
}

export const COGNITO_POOL_IDS = {
    "Beta-us-west-2": "us-east-1:2803f2a7-5e9c-4ce3-9a2b-bf2845bf347f",
    "Gamma-NA-us-east-1": "us-east-1:2803f2a7-5e9c-4ce3-9a2b-bf2845bf347f",
    "Gamma-JP-us-west-2": "us-west-2:e6e11c48-9fcd-4c88-937d-38b3c6def0b4",
    "Gamma-EU-eu-west-1": "eu-west-1:7487eda7-94b0-498b-9d4a-0e5452575a45",
    "Prod-NA-us-east-1": "us-east-1:b49d0a19-1fa8-446e-9ae4-ad18f7e89021",
    "Prod-JP-us-west-2": "us-west-2:e6e11c48-9fcd-4c88-937d-38b3c6def0b4",
    "Prod-EU-eu-west-1": "eu-west-1:7487eda7-94b0-498b-9d4a-0e5452575a45"
}

export const META_DATA_KEYS = ["tape_damaged_key",
    "TARGET_STATION",
    "transporterId",
    "transporterName",
    "hazmat_united_nations_regulatory_id",
    "packageSize",
    "sectorCode"
];

export const SAL_COLOR_AUDIT_INCLUDE_CATEGORIES = [
    "DRIVER_LEFT_BEHIND",
    "MISSORT",
    "WRONG_STATION",
    "WRONG_PLANNED_STATION",
    "WRONG_CARRIER",
    "WRONG_STATION_SAME_CITY",
    "CUSTOMER_CANCELED",
    "CUSTOMER_RESCHEDULED",
    "CUSTOMER_ADDRESS_CHANGE",
    "WRONG_STATION_SAME_COUNTRY",
    "WRONG_STATION_DIFFERENT_COUNTRY",
    "PICK_LEFT_BEHIND",
    "DISPATCH_LEFT_BEHIND",
    "PICK_SCANNED_TO_PROBLEM_SOLVE_BIN",
    "STOW_SCANNED_TO_PROBLEM_SOLVE_BIN",
    "STOW_WRONG_AISLE_CLUSTER",
    "INDUCT_DWELLING",
    "INDUCT_INVALID_MCO_CYCLE",
    "INDUCT_PACKAGE_OUTSIDE_JURISDICTION",
    "INDUCT_MISSING_CONTAINER_PLAN",
    "INDUCT_RECIRC_FAILURE",
    "STOW_OVERSIZE",
    "INDUCT_SCHEDULED_FOR_FUTURE",
    "INDUCT_READY_FOR_FC_RETURN",
    "STOW_MISSORT",
    "DELIVERY_DATE_CHANGE",
    "INDUCT_INVALID_STATUS",
    "PACKAGE_ROUTE_INVALID",
    "RTO_WRONG_STATION_SAME_CITY",
    "RTO_WRONG_PLANNED_STATION",
    "PACKAGE_AUDIT",
    "WRONG_STATION_SAME_CITY_V2",
    "WRONG_CARRIER_V2",
    "INCORRECT_PACKAGE_SIZE"
];

export const ACTION_TYPES_WITH_DESCRIPTION = [
  "DAMAGED_SALVAGEABLE",
  "UNSALVAGEABLE",
  "CLUSTER_TRANSFER_REPLAN",
  "REASSIGN_TO_ANOTHER_DRIVER",
  "ITEM_REPLAN",
  "PREPARE_FOR_INDUCT",
  "ACTION_STOW_FAIL_RTS",
  "MILK_RUN",
  "WRONG_STATION_DELIVER",
  "MANUAL_RETURN_TO_FC",
  "CARRIER_MISSORT",
  "REPLAN",
  "PRINT_ACTIVE_LABEL",
  "STOW",
  "STAGE_ITEM",
  "PRINT_LABEL_AND_REPLAN",
  "RTO_DESTROY",
  "RTO_UNSALVAGEABLE",
  "RTO_REPLAN",
  "RTO_PRINT_LABEL",
  "RTS_PRINT_LABEL",
  "INDUCT_PRINT_LABEL",
  "NETWORK_MISSORT_REPLAN"
];

export const LOCAL_STORAGE_PS_EXECUTION_KEYS = {
    SCANNED_SCANNABLEIDS:"SCANNED_SCANNABLEIDS",
    SHIPMENTS_DETAIL:"SHIPMENTS_DETAIL",
    PROBLEM_TYPE_TO_CATEGORY_MAP: "PROBLEM_TYPE_TO_CATEGORY_MAP",
    SELECTED_PROBLEM_TYPE:"SELECTED_PROBLEM_TYPE",
    SELECTED_PROBLEM_CATEGORY:"SELECTED_PROBLEM_CATEGORY",
    SELECTED_PROBLEM_ACTION:"SELECTED_PROBLEM_ACTION",
    PROBLEM_CATEGORY_CONFIG: "PROBLEM_CATEGORY_CONFIG",
    PROBLEM_ACTION_CONFIG: "PROBLEM_ACTION_CONFIG",
    PROBLEM_ACTION_NEXT_COMPONENT_DETAILS: "PROBLEM_ACTION_NEXT_COMPONENT_DETAILS",
    SET_EXCEPTION_RESOLUTION_RESPONSE: "SET_EXCEPTION_RESOLUTION_RESPONSE",
    SET_API_EXECUTION_RESPONSE: "SET_API_EXECUTION_RESPONSE",
    SCANNED_CONTENT: "SCANNED_CONTENT",
    RETRY_COUNTER : "RETRY_COUNTER",
    STATION_SECTORS: "STATION_SECTORS",
    SELECTED_QUEUE_DETAILS: "SELECTED_QUEUE_DETAILS",
    QUEUE_TO_CATEGORIES: "QUEUE_TO_CATEGORIES",
    EXCEPTION_QUEUE_TO_COUNT: "EXCEPTION_QUEUE_TO_COUNT",
    EXCEPTION_SHIPMENT_SUMMARY_REPORT: "EXCEPTION_SHIPMENT_SUMMARY_REPORT",
    OPEN_EXCEPTION_SHIPMENT_WITH_DETAILS: "OPEN_EXCEPTION_SHIPMENT_WITH_DETAILS",
    LEFT_TO_FIX_FILTER: "LEFT_TO_FIX_FILTER",
    EXCEPTION_SHIPMENTS_WITH_DETAILS: "EXCEPTION_SHIPMENTS_WITH_DETAILS",
    SDC_VIEW_HEADING_KEY: "SDC_VIEW_HEADING_KEY",
    DRIVER_ACTION_TRANSPORTER: "DRIVER_ACTION_TRANSPORTER",
    UNSUCCESSFUL_QR_SCANS: "UNSUCCESSFUL_QR_SCANS"

}

export const PROBLEM_ACTION_API = {
    UPDATE_EXCEPTION_SHIPMENTS:"updateExceptionShipments",
    CREATE_OPEN_EXCEPTION: "CREATE_OPEN_EXCEPTION",
    CREATE_MULTIPLE_OPEN_EXCEPTIONS: "CREATE_MULTIPLE_OPEN_EXCEPTIONS",
    GET_STAGING_AREA_FOR_PICKLIST: "getStagingAreaForPickList",
    GET_PRINT_LABEL: "printLabel"
};

export const NOTIFICATION_TYPE = {
    "ALERT": "error",
    "WARNING": "warning",
    "SUCCESS": "success"
}

export const COMMON_PROBLEM_SOLVE_PREFIX = "/ps/"

export const PROBLEM_CATEGORY_URL_PREFIX = `${COMMON_PROBLEM_SOLVE_PREFIX}problemCategory/`

export const PROBLEM_CATEGORY_STRING_PREFIX = "ps_";
export const BACKGROUND_DEFAULT = "default";

export const SCAN_TYPE = {
    QR_CODE:"QR_CODE",
    PACKAGE: "PACKAGE"
};
export const PICKUP_EXCEPTIONS = {
    CUBE_OUT: "CUBE_OUT",
    MISSING_PACKAGE: "MISSING_PACKAGE"
};
export const PICKUP_EXCEPTIONS_TARGET_ACTION = {
    CUBE_OUT_REPLAN: "CUBE_OUT_REPLAN",
    IDENTIFY_MISSING: "IDENTIFY_MISSING"
};
export const MISSING = "MISSING";
export const SPACE = " ";
export const COMMA = ", ";
export const SWIPE_DOWN_TO_REFRESH_ROUTES = ["/"];
export const SWIPE_ENABLED = "auto";
export const SWIPE_DISABLED = "none";

export const LOST_BUT_FOUND = "LOST_BUT_FOUND";
export const LABEL_PRINT = "LABEL_PRINT";
export const ENTITY_TYPES = {"ROUTE":"ROUTE", "DRIVER":"DRIVER", "PACKAGE":"PACKAGE"};
export const UNASSIGN_ROUTE = "UNASSIGN_ROUTE";
export const TAPE_DAMAGED_CATEGORIES = ["DAMAGED_PACKAGE", "PICK_DAMAGED", "STOW_DAMAGED"]
export const RETURN_ELIGIBLE_CATEGORIES = ["STOW_SCANNED_TO_PROBLEM_SOLVE_BIN"]

export const DRIVER_ENTITY_HEADING = {
    OVERVIEW: "OVERVIEW",
    DETAILS: "DETAILS"
};
export const HAZMAT_LABEL_CHECK_ELIGIBLE_ACTION = ["DAMAGED_SALVAGEABLE"];

export const NET_CONF_MODULE = {
    "STOW": "StowWebsite"
};

export const ExceptionAction = {
    NO_OP: "NO_OP",
    CUBE_OUT_REPLAN: "CUBE_OUT_REPLAN",
    REPLAN: "REPLAN"
};

export const ExceptionCategories = {
    MISSING: "MISSING",
    CUBE_OUT: "CUBE_OUT"
};

export const ERROR_CODES = {
    INCORRECT_STAGING_LOCATION: "INCORRECT_STAGING_LOCATION",
    INVALID_DESTINATION_LOCATION: "INVALID_DESTINATION_LOCATION"
}

export const ResponseStatus = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    WARN: "WARN",
    WARNING: "WARNING"
}

export const UpdateExceptionResponseCode =  {
    SUCCESS: "SUCCESS",
    INVALID_STATION: "INVALID_STATION",
    INELIGIBLE_SHIPMENT: "INELIGIBLE_SHIPMENT",
    DEPENDENCY_FAILURE: "DEPENDENCY_FAILURE"
}

export const PHARMACY_SHIPMENT_CATEGORY = "Wolfgang";
export const SWA_SHIPMENT_CATEGORY = "SWA";
export const NON_SWA_SHIPMENT_CATEGORY = "Non-SWA";
export const NETWORK_MISSORT_REPLAN = "NETWORK_MISSORT_REPLAN";

export const OVERRIDE_PACKAGE_VOLUME_ACTION = "OVERRIDE_PACKAGE_VOLUME";

export const PackageSizes =  ["SMALL", "VOLUMETRIC"];

export const OVERRIDE_PACKAGE_SECTOR_ACTION = "OVERRIDE_PACKAGE_SECTOR";

export const MAX_UNSUCCESSFUL_SCAN_LIMIT = '5';
import React, {useReducer, useState} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {GridView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {
    OVERRIDE_PACKAGE_SECTOR_ACTION_TYPE,
    OverridePackageSectorInitialState,
    OverridePackageSectorReducer
} from "../../reducers/OverridePackageSectorReducer";
import InProgress from "../common/InProgress";
import NotificationMessage from "../common/NotificationMessage";
import {getProblemCategory, getStationSectors} from "../../dataStore/PSExecutionDataStore";
import {
    OVERRIDE_PACKAGE_SECTOR_ACTION,
    PROBLEM_ACTION_API,
    PROBLEM_CATEGORY_STRING_PREFIX
} from "../../constants/Constants";
import {FormattedMessage} from "react-intl";
import {ScannedPackagesListView} from "../scannedpackageslist/ScannedPackagesListView";

const initialState = {
    sectorCode: "",
};

const SelectSector = ({history}) => {
    const [state, setState] = useState(initialState);
    const [{loading, notificationType, notificationMessageId}, dispatcher] = useReducer(OverridePackageSectorReducer, OverridePackageSectorInitialState);

    const assignSectorToPackage = () => {
        dispatcher({
            type: OVERRIDE_PACKAGE_SECTOR_ACTION_TYPE.OVERRIDE_PACKAGE_SECTOR,
            data: {
                api: PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS,
                properties: {
                    targetAction: OVERRIDE_PACKAGE_SECTOR_ACTION
                },
                additionalProperties: {"sectorCode": state.sectorCode}
            },
            dispatch: dispatcher
        })
    }

    const selectSector = (sector) => {
        setState({sectorCode: sector});
    }

    const getSectorGridProps = () => {
        return {
            title: <FormattedMessage id="assign_sector"/>,
            gridTitle: "Sectors",
            dataSnapshots: getStationSectors().map(sector => {
                return {
                    title: sector,
                    description: sector
                }
            }),
            onClick: selectSector,
            dimension: 3
        }
    }

    return (
        <Column height="100%" heights={["fill", "fit"]}>
            <Column spacingInset="medium">
                {loading && <InProgress/>}
                {notificationType &&
                <NotificationMessage dispatch={dispatcher} actionType={OVERRIDE_PACKAGE_SECTOR_ACTION_TYPE.CLOSE_NOTIFICATION}
                                     type={notificationType} messageId={notificationMessageId} messageValues={{}}/>}
                {/*<Text type="h300"><FormattedMessage id={"select_package_size"}/></Text>*/}
                <GridView {...getSectorGridProps()} />
            </Column>
            <Column alignmentVertical="bottom" width="100%" spacingInset="medium medium xlarge medium">
                {state.sectorCode !== "" &&
                <Button onClick={() => assignSectorToPackage()}><FormattedMessage id="continue_solving"/></Button>}
                <Button type="secondary" onClick={() => history.goBack()}><FormattedMessage id="cancel_text"/></Button>
                <div className="spaceBlockerMedium"/>
            </Column>
            <ScannedPackagesListView rightHeader={PROBLEM_CATEGORY_STRING_PREFIX + getProblemCategory()}/>
        </Column>);
}

SelectSector.propTypes = {
    history: PropTypes.object,
};
export default withRouter(SelectSector);
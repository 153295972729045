import {NPSWClient} from "../../client/NPSWClient";
import * as NPSWClientMock from "../../client/NPSWClientMock";

import {SPSClient} from "../../client/SPSClient";
import * as SPSClientMock from "../../client/SPSClientMock";
import {DEV_SUPPORT} from "../../dev-support";


export const getNPSWClient = () => {
    return DEV_SUPPORT.IS_NETWORK_MOCK ? NPSWClientMock.NPSWClient : NPSWClient;
}

export const getSPSClient = () => {
    return DEV_SUPPORT.IS_NETWORK_MOCK ? SPSClientMock.SPSClient : SPSClient;
}

import {
    ERROR_CODES,
    META_DATA_KEYS,
    NOTIFICATION_TYPE,
    OVERRIDE_PACKAGE_VOLUME_ACTION,
    OVERRIDE_PACKAGE_SECTOR_ACTION,
    PROBLEM_ACTION_API,
    ResponseStatus,
    TARGET_PROBLEM_STATUS,
    UpdateExceptionResponseCode
} from "../constants/Constants";
import {
    getApiExecutionResponse, getProblemAction,
    getProblemActionConfig,
    getProblemCategory,
    getScannedScannableIds,
    getShipmentDetail,
    saveApiExecutionResponse,
    saveProblemActionConfig,
    saveProblemActionNextComponentDetails
} from "../dataStore/PSExecutionDataStore";
import {UpdateExceptionShipmentsAPI} from "../network/apis/npsw/UpdateExceptionShipmentsAPI";
import {UpdateExceptionShipmentsForMultipleProblemCategoryAPI} from "../network/apis/npsw/UpdateExceptionShipmentsForMultipleProblemCategoryAPI";
import {GetStagingAreaForPickListAPI} from "../network/apis/npsw/GetStagingAreaForPickListAPI";
import {ProblemActionReducerActionType} from "../reducers/ProblemActionReducer";
import {getItemId, getPickListId, getRouteId} from "../utils/ShipmentUtils";
import {GetPackageLabelAPI} from "../network/apis/npsw/GetPackageLabelAPI";
import {getLabelType} from "../print/LabelTypeConfig";
import Printer from "../print/Printer";
import {Logger} from "@amzn/dolphin-web-framework";

export const getMetaData = (additionalProperties) => {
  let metaData = {};
  for (let key in additionalProperties){
    if(META_DATA_KEYS.includes(key)) {
      metaData[key]=additionalProperties[key]
    }
  }
  return metaData
};

const getSuccessfulExceptionResolvedCount = (exceptionResponse) => {
  var successCount = 0;
  exceptionResponse.updateExceptionShipmentResponses.forEach((response) => {
    if (response && response.success === true) successCount++;
  });
  return successCount;
};

const getExceptionShipmentAdditionalInformation = (exceptionResponse) => {
  var additionalInformation = {};
  exceptionResponse.updateExceptionShipmentResponses.forEach((response) => {
    if(response.success === true && response.scannableId && response.additionalInformation) {
      additionalInformation[response.scannableId] = response.additionalInformation;
    }
  });
  return additionalInformation;
};

const getExceptionShipmentErrorCode = (exceptionResponse) => {
  var errorCodes = {};
  exceptionResponse.updateExceptionShipmentResponses.forEach((response) => {
    if(response.success === false && response.scannableId && response.exception && response.exception.message) {
      if (response.exception.message.includes(ERROR_CODES.INCORRECT_STAGING_LOCATION)) {
        errorCodes[response.scannableId] = ERROR_CODES.INCORRECT_STAGING_LOCATION;
      } else if (response.exception.message.includes(ERROR_CODES.INVALID_DESTINATION_LOCATION)) {
        errorCodes[response.scannableId] = ERROR_CODES.INVALID_DESTINATION_LOCATION;
      }
    }
  });
  return errorCodes;
};

export const processApiExecutionResponse = (apiResponse) => {
  if(apiResponse.updateExceptionShipmentResponses === undefined) {
    saveApiExecutionResponse(apiResponse)
    return {
      exceptionResolutionResult: {},
      loadNextComponent: true
    }
  }
  let successCount = getSuccessfulExceptionResolvedCount(apiResponse)
  let exceptionResolutionResult = {}
  let loadNextComponent = false
  if (successCount === 0) {
    exceptionResolutionResult = {
      isError: true,
      errorCodes: getExceptionShipmentErrorCode(apiResponse)
    }
  } else {
    exceptionResolutionResult = {
      successCount: getSuccessfulExceptionResolvedCount(apiResponse),
      totalPackages: getScannedScannableIds().length,
      additionalInformation: getExceptionShipmentAdditionalInformation(apiResponse)
    }
    loadNextComponent = true
  }
  return {
    exceptionResolutionResult: exceptionResolutionResult,
    loadNextComponent: loadNextComponent
  }
}
export const updateNextComponentDetails = () => {
  const problemActionConfig = getProblemActionConfig()
  let newProblemActionConfig = []
  let problemActionNextComponentDetails = {}
  if(problemActionConfig.length > 1) {
    newProblemActionConfig = problemActionConfig.slice(1)
    problemActionNextComponentDetails = newProblemActionConfig[0]
  }
  saveProblemActionConfig(newProblemActionConfig)
  saveProblemActionNextComponentDetails(problemActionNextComponentDetails)
}

export const callActionApi = (data, dispatch, nextAction, errorAction, printerErrorAction) => {
  switch (data.api) {
    case PROBLEM_ACTION_API.UPDATE_EXCEPTION_SHIPMENTS: {
      Logger.log.info("Update Exception shipments problem action called.")
      callUpdateActionShipments(data, dispatch, nextAction, errorAction, TARGET_PROBLEM_STATUS.RESOLVED)
      break
    }
    case PROBLEM_ACTION_API.CREATE_OPEN_EXCEPTION: {
      callUpdateActionShipments(data, dispatch, nextAction, errorAction, TARGET_PROBLEM_STATUS.OPEN);
      break;
    }
    case PROBLEM_ACTION_API.CREATE_MULTIPLE_OPEN_EXCEPTIONS:{
      callUpdateMultipleActionShipments(data, dispatch, nextAction, errorAction);
      break;
    }
    case PROBLEM_ACTION_API.GET_STAGING_AREA_FOR_PICKLIST:{
      callGetStagingAreaForPickList(data, dispatch, nextAction, errorAction);
      break;
    }
    case PROBLEM_ACTION_API.GET_PRINT_LABEL:{
        callGetPrintLabel(data, dispatch, nextAction, printerErrorAction, errorAction);
      break;
    }
    default :
      return
  }
};

const callUpdateActionShipments = (data, dispatcher, nextAction, errorAction, targetProblemStatus) => {
  UpdateExceptionShipmentsAPI({
        scannableIdList: getScannedScannableIds(),
        problemCategory: getProblemCategory(),
        targetProblemStatus: targetProblemStatus,
        targetAction: data.properties.targetAction
      },
      getMetaData(data.additionalProperties),
      nextAction,
      errorAction,
      dispatcher);
};

const callUpdateMultipleActionShipments = (data, dispatcher, nextAction, errorAction) => {
  UpdateExceptionShipmentsForMultipleProblemCategoryAPI({
    updateExceptionShipmentParams: data.properties.request
  }, nextAction, errorAction, dispatcher);
};

const callGetStagingAreaForPickList = (data, dispatcher, nextAction, errorAction) => {
  let picklistId = getPickListId(getShipmentDetail())
  if(picklistId) {
    GetStagingAreaForPickListAPI(
        {
          pickListId: picklistId,
          itemId: getItemId(getShipmentDetail()),
          routeId: getRouteId(getShipmentDetail())
        }, nextAction, errorAction, dispatcher);
  } else {
    dispatcher({
      type: ProblemActionReducerActionType.SHOW_ERROR_FETCHING_STAGING_AREA,
      dispatcher: dispatcher
    })
    console.log("messageid: error_fetching_staging_area")
  }
};


const callGetPrintLabel = (data, dispatcher, nextAction, printerErrorAction, errorAction) => {
  Printer.getConnectionStatus().then((connectionStatus) => {
    if (connectionStatus === "true") {
      GetPackageLabelAPI({
            scannableId: getScannedScannableIds()[0],
            labelType: getLabelType(data.properties.labelType)
          }, dispatcher,
          nextAction,
          printerErrorAction,
          errorAction);
    } else {
      dispatcher({
        type: printerErrorAction
      })
    }
  }).catch(() => {
    dispatcher({
      type: printerErrorAction
    })
  });
};

export const getStageAreaLabel = () => {
  let stageAreaLabel = ""
  const apiResponse = getApiExecutionResponse();
  if(apiResponse && apiResponse.stagingAreaList && apiResponse.stagingAreaList.length > 0 ) {
    stageAreaLabel = apiResponse.stagingAreaList[0].label;
  }
  return stageAreaLabel
};

export const areStagingCartsPresent = () => {
  return getApiExecutionResponse()?.stagingCarts?.length > 0;
}

export const getRecommendedCartLabel = () => {
  let recommendedCartLabel = "";
  const apiResponse = getApiExecutionResponse();
  if(apiResponse?.stagingCarts?.length > 0 ) {
    recommendedCartLabel = apiResponse.stagingCarts[0].label;
  }
  return recommendedCartLabel;
};

export const getContainerTaskId = () => {
    return getApiExecutionResponse()?.containerTaskId;
};

export const getStageAreaLocation = () => {
  let stageAreaLocation = "";
  const apiResponse = getApiExecutionResponse();
  if(apiResponse && apiResponse.stagingAreaList && apiResponse.stagingAreaList.length > 0 ) {
    stageAreaLocation = apiResponse.stagingAreaList[0].id;
  }
  return stageAreaLocation
};

export const isHazmatPackage = () => {
  return getShipmentDetail()
      && getShipmentDetail().length
      && getShipmentDetail()[0].hazmatCode;
};

export const getHazmatCode = () => {
  return isHazmatPackage() && getShipmentDetail()[0].hazmatCode;
};

export const handleInlineError = (data, nextAction, dispatcher) => {
  dispatcher({
    type: nextAction,
    data: data,
    dispatcher: dispatcher
  });
};

export const getMessageAndAlertTypeForUpdateExceptionShipment = (data) => {
  if (data && data.updateExceptionShipmentResponses.length > 0
      && data.updateExceptionShipmentResponses[0].userOutput) {
    const userOutput = data.updateExceptionShipmentResponses[0].userOutput;
    const notificationMessageId = getMessage(userOutput);
    const alertType = (userOutput.responseStatus === ResponseStatus.WARN || userOutput.responseStatus === ResponseStatus.WARNING) ?
        NOTIFICATION_TYPE.WARNING : NOTIFICATION_TYPE.ALERT;
    return {
      alertMessageId:notificationMessageId,
      alertType: alertType
    };
  } else if ([OVERRIDE_PACKAGE_VOLUME_ACTION, OVERRIDE_PACKAGE_SECTOR_ACTION].some(action => action === getProblemAction())) {
      return {
          alertMessageId: "internal_error",
          alertType: NOTIFICATION_TYPE.ALERT
      };
  }
  return {
    alertMessageId: "transfer_scan_error",
    alertType: NOTIFICATION_TYPE.ALERT
  };
}

function getMessage(userOutput) {
  switch(userOutput.responseCode) {
    case UpdateExceptionResponseCode.INVALID_STATION:
      return "transfer_scan_warning";
    case UpdateExceptionResponseCode.INELIGIBLE_SHIPMENT:
      return "ineligible_shipments";
    case UpdateExceptionResponseCode.DEPENDENCY_FAILURE:
      return "internal_error";
    default:
      return "transfer_scan_error";
  }
}